<template>
  <div class="container">
    <div class="main">
      <div class="info">
        <div class="flexSt info_header">
          <div class="line"></div>
          <div>基本信息</div>
        </div>
        <div class="info_cont">
          <div class="flexSb list">
            <div class="flexSt">
              <div class="title">上课时间 :</div>
              <el-input disabled v-model="Info.showtime"></el-input>
            </div>
            <div class="flexSt">
              <div class="title">课程类型 :</div>
              <el-input disabled v-model="Info.course_typeName"></el-input>
            </div>
          </div>
          <div class="flexSb list">
            <div class="flexSt">
              <div class="title">班级名称 :</div>
              <el-input disabled v-model="Info.class_name"></el-input>
            </div>
            <div class="flexSt">
              <div class="title">所属年级 :</div>
              <el-input disabled v-model="Info.grade_name"></el-input>
            </div>
          </div>
          <div class="flexSb list">
            <div class="flexSt">
              <div class="title">任课教师 :</div>
              <el-input disabled v-model="Info.teacher_name"></el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          router>
          <el-menu-item
            v-if="Info.course_type == 1"
            index="preparation"
            :route="{
              path: '/preparation',
              query: { val: JSON.stringify(Info) },
            }">
            <i class="iconfont icon-mb-book"></i>
            备课情况</el-menu-item
          >

          <el-menu-item
            index="classstyle"
            :route="{
              path: '/classstyle',
              query: { val: JSON.stringify(Info) },
            }">
            <i class="iconfont icon-antOutline-camera"></i>
            课堂风采</el-menu-item
          >
          <el-menu-item
            v-if="Info.course_type == 1"
            index="attendance"
            :route="{
              path: '/attendance',
              query: { val: JSON.stringify(Info) },
            }">
            <i class="iconfont icon-stLine-blackboard-l"></i>
            出勤记录</el-menu-item
          >
        </el-menu>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Info: {
        time: "",
        type: "",
        name: "",
        grade: "",
        teacher: "",
      },
      activeIndex: "preparation",
    };
  },
  watch: {
    $route: function (to, from) {
      console.log(to, "---to");
      console.log(from, "---from");
      console.log(this.$route);
      this.activeIndex = this.$route.name;
    },
  },
  mounted() {
    this.activeIndex = this.$route.name;
    if (this.$route.query.val) {
      this.Info = JSON.parse(this.$route.query.val);
      let val = this.Info;
      console.log(val);
      this.Info.showtime = val.date + val.time;
      this.Info.course_typeName =
        val.course_type == 1 ? "常规体育课" : "大课间";
    }
  },
  methods: {
    handleSelect() {},
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
}
.main {
  flex: 1;
  width: 100%;
  .info {
    width: 100%;
    // height: 25%;
    background-color: #fff;
    border-radius: 4px;
    padding: 25px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .info_header {
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      padding-bottom: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(220, 223, 230, 1);
      .line {
        width: 3px;
        height: 22px;
        background-color: rgba(81, 108, 247, 1);
        margin-right: 11px;
      }
    }
    .info_cont {
      padding: 0 80px;
      box-sizing: border-box;
      .list {
        margin-top: 20px;
      }
      .title {
        width: 70px;
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
      }
      .el-input {
        width: 330px;
        margin-left: 9px;
      }
      /deep/ .el-input.is-disabled .el-input__inner {
        color: rgba(16, 16, 16, 1);
      }
    }
  }
  .box {
    background-color: #fff;
    padding-bottom: 25px;
    box-sizing: border-box;
    /deep/.el-menu {
      width: 100% !important;
    }
    /deep/ .el-menu-item {
      font-size: 16px;
      i {
        font-size: 20px;
      }
    }
    /deep/.is-active {
      font-weight: 600;
      color: rgba(81, 108, 247, 1);
      border-bottom: 3px solid rgba(81, 108, 247, 1);
      i {
        font-weight: normal;
      }
    }
  }
}
</style>
